import './Post.css'

import React from 'react'

const Post = ({ post: { title, body, imgUrl, author }, index }) => {
  return (
    <div className="post-container">
      <h1 className="heading">{title}</h1>

      <p>{body}</p>
      <div className="info"></div>
    </div>
  )
}

export default Post
