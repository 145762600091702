import React from 'react'

import Posts from '../components/Posts/Posts'
import { Cell } from '../components/grid/Cell'

const Blog = () => {
  return (
    <div className="dark:text-white">
      <Posts />
    </div>
  )
}

export default Blog
