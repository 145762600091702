import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Layout } from './components/navbar/Layout'
import { solutionGameDate } from './lib/words'
import { setGameDate } from './lib/words'
import Blog from './pages/Blog'
import Home from './pages/Home'
import Howto from './pages/Howto'
import Pastgames from './pages/Pastgames'
import Statsmodal from './pages/Statsmodal'

function App() {
  // let initialDate = { solutionGameDate }
  // let handleSelectDate = { handleSelectDate: Date }

  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/howto" element={<Howto />} />
          <Route path="/pastgames" element={<Pastgames />} />
          <Route path="/Blog" element={<Blog />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  )
}

export default App
