import './Posts.css'

import React from 'react'

import Post from '../Post/Post'

const Posts = () => {
  const blogPosts = [
    {
      title:
        'The Excitement of Word Guess: Unlocking the Power of Linguistic Puzzles',
      body: `Word Guess, a timeless game of language and deduction, has captivated minds for generations. With its simple yet engaging concept, it challenges players to decipher hidden words through clues and hints. The allure lies in the thrill of unraveling complex combinations and the satisfaction of solving a linguistic puzzle.

	At its core, Word Guess stimulates the mind and encourages critical thinking. Players must analyze the given clues, carefully considering word length, letter patterns, and context to narrow down the possibilities. This process engages cognitive skills, such as logic, reasoning, and problem-solving, while also expanding vocabulary and enhancing language proficiency.
	
	Beyond its intellectual benefits, Word Guess fosters social interaction and collaboration. Whether played in person or online, it brings people together to engage in friendly competition or cooperative play. It sparks conversations, ignites laughter, and creates lasting memories as players work together to unravel the mystery word.
	
	Word Guess is also a versatile game that can be customized to suit various age groups and skill levels. From children learning to spell to adults looking to challenge their linguistic prowess, the game offers a wide range of difficulty levels and themes. Its adaptability makes it an ideal choice for family gatherings, classroom activities, or even as an icebreaker in corporate settings.
	
	Moreover, the digital age has expanded the horizons of Word Guess, providing platforms and apps that offer new dimensions to the game. Online leaderboards, multiplayer options, and interactive features have transformed Word Guess into a dynamic and immersive experience, attracting players from all walks of life.
	
	In conclusion, Word Guess transcends generations and cultures, providing an enjoyable and educational experience for players of all ages. With its ability to sharpen mental acuity, foster social connections, and adapt to evolving technology, it continues to stand the test of time. So, gather your friends, unleash your inner wordsmith, and embark on a captivating journey of linguistic discovery with Word Guess.
	
	`,
      author: 'Nishant Singh ',
      imgUrl:
        'https://media.geeksforgeeks.org/img-practice/banner/diving-into-excel-thumbnail.png',
    },
    {
      title:
        'Word Quiz: Test Your Vocabulary Skills and Expand Your Knowledge!',
      body: `Are you a word enthusiast looking for a challenge? Look no further than the captivating world of word quizzes! A word quiz is a thrilling way to test your vocabulary and language prowess while having a great time.

	  These interactive quizzes present you with various word-related questions, such as synonyms, antonyms, word definitions, anagrams, and more. Whether you're a student aiming to improve your language skills or an avid reader seeking intellectual stimulation, word quizzes offer a fun and engaging experience.
	  
	  Engaging in word quizzes regularly has several benefits. It enhances your vocabulary by introducing new words and their meanings. Additionally, it helps reinforce your understanding of word relationships, such as synonyms and antonyms, promoting clearer and more effective communication.
	  
	  Word quizzes also boost critical thinking and problem-solving abilities. They require you to analyze word structures, identify patterns, and make connections, stimulating your cognitive skills. Furthermore, the competitive nature of word quizzes can foster a healthy sense of challenge and motivation to strive for improvement.
	  
	  Fortunately, word quizzes are easily accessible online. Numerous websites and mobile applications offer an extensive range of quizzes catering to different difficulty levels and interests. You can enjoy quick quizzes during your breaks or dive into longer sessions for a more immersive experience.
	  
	  So, are you ready to put your linguistic abilities to the test? Challenge yourself with word quizzes and embark on an exciting journey of linguistic exploration. Expand your vocabulary, sharpen your mind, and have a blast along the way. Get started today and unlock the power of words!
	  `,
      author: 'Suresh Kr',
      imgUrl:
        'https://media.geeksforgeeks.org/img-practice/banner/coa-gate-2022-thumbnail.png',
    },
    {
      title:
        'The Thrilling World of Guess Word Online: Unleash Your Word-Guessing Skills!',
      body: `Are you ready to test your vocabulary prowess and engage in some mind-boggling fun? Look no further than the captivating realm of guess word online! This exciting genre of interactive games has taken the internet by storm, offering a thrilling experience for word enthusiasts of all ages.

	  Guess word online games are designed to challenge your ability to decipher words using various clues and hints. With a wide range of formats and themes available, you can immerse yourself in an endless array of word puzzles, riddles, and brain teasers. Whether you prefer classic word association games or innovative twists on traditional guessing games, there's something for everyone.
	  
	  One of the greatest advantages of guess word online games is their accessibility. With just a few clicks, you can embark on an exhilarating word-guessing adventure from the comfort of your own home. These games are available on multiple platforms, including websites, mobile apps, and social media platforms, ensuring that you can enjoy them wherever and whenever you please.
	  
	  Guess word online games also provide a fantastic opportunity to expand your vocabulary. As you encounter new words and phrases, your linguistic skills are put to the test, enhancing your ability to think critically and make connections. Additionally, many games offer features like hints and tutorials, allowing you to learn new words and improve your gameplay along the way.
	  
	  Furthermore, guess word online games often incorporate multiplayer features, enabling you to compete against friends, family, or even strangers from around the world. Engaging in friendly competition adds an extra layer of excitement and motivation, as you strive to outsmart and outguess your opponents. It's a fantastic way to connect with others who share your passion for words and challenge yourself in a dynamic and engaging environment.
	  
	  So, what are you waiting for? Dive into the captivating world of guess word online and unlock your inner wordsmith. Whether you're looking to unwind after a long day or engage in a thrilling mental workout, these games provide endless entertainment and intellectual stimulation. Sharpen your wits, expand your vocabulary, and embark on an unforgettable journey of word discovery. Get ready to guess your way to victory!
	  
	  `,
      author: 'Monu Kr',
      imgUrl:
        'https://media.geeksforgeeks.org/img-practice/banner/google-test-series-thumbnail.png',
    },
  ]

  return (
    <div className="posts-container">
      {blogPosts.map((post, index) => (
        <Post key={index} index={index} post={post} />
      ))}
    </div>
  )
}

export default Posts
