import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const BlogModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Guess Word" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-left text-sm text-gray-500 dark:text-gray-300">
        A Fun and Engaging Game for All Ages. Guess Word is a popular
        word-guessing game that has been enjoyed by people of all ages for
        decades. The game is simple yet challenging, and can be played with as
        many players as desired. It is a great way to pass the time, stimulate
        the mind, and bond with family and friends.
        <br></br>
        <br></br>
        The rules of the game are flexible and can be adjusted based on the
        preferences of the players. For instance, players can set a time limit
        for each turn or allow the use of hints or gestures to help with
        guessing. The game can also be played in teams, with each team taking
        turns to provide the clues.
        <br></br>
        <br></br>
        Guess Word is a game that challenges the players' vocabulary,
        creativity, and critical thinking skills. It requires them to think of
        words that are relevant to the given clue or category, and to choose
        clues that are neither too easy nor too difficult for the other players.
        It also encourages players to think outside the box and to come up with
        clever and witty clues that will stump their opponents.
        <br></br>
        Apart from being a fun and engaging game, Guess Word also has several
        educational benefits. It can improve players' language skills, expand
        their knowledge of words and their meanings, and enhance their cognitive
        abilities. It can also promote teamwork and communication skills, as
        players work together to guess the word or provide clues.
        <br></br>
        Guess Word is a game that can be played anytime, anywhere, and by
        anyone. It requires no special equipment or setup, and can be adapted to
        suit the players' preferences and skill levels. It is a game that can be
        enjoyed by children and adults alike, making it a great activity for
        family gatherings, parties, or game nights.
        <br></br>
        In conclusion, Guess Word is a timeless classic that has stood the test
        of time. It is a game that is easy to learn, yet challenging to master.
        It is a game that promotes creativity, critical thinking, and teamwork,
        while also providing hours of fun and entertainment. So why not give it
        a try and see how many words you can guess?
      </p>

      <h1>Word Game Online</h1>
      <p className="text-left text-sm text-gray-500 dark:text-gray-300">
        A Fun and Educational Way to Spend Your Time.<br></br> Do you love
        playing with words and learning new ones? Do you enjoy challenging your
        brain and testing your vocabulary? If you answered yes, then you might
        want to try some word games online. Word games are puzzles that involve
        forming, finding, or guessing words from letters, clues, or grids. They
        are a great way to have fun and improve your language skills at the same
        time.<br></br>
        There are many types of word games online that you can play for free on
        various websites. Here are some of the most popular ones: <br></br>
        Crosswords: Crosswords are word games where you have to fill in a grid
        with words that match the given clues. You can choose from different
        levels of difficulty and themes. Some of the best websites for playing
        crosswords online are Arkadium, The New York Times, and Wordgames.com.
        <br></br> Word Searches: Word searches are word games where you have to
        find hidden words in a grid of letters. The words can be horizontal,
        vertical, diagonal, or backwards. You can also choose from different
        categories and sizes. Some of the best websites for playing word
        searches online are Arkadium, Penny Dell Puzzles, and Mindgames.com.
        <br></br> Word Connect: Word connect is a word game where you have to
        swipe letters to form words that fit into a crossword-style grid. You
        can earn coins and hints as you progress through the levels. Some of the
        best websites for playing word connect online are Arkadium,
        Wordgames.com, and Gamesonline.org. <br></br>Word Wipe: Word wipe is a
        word game where you have to make words from adjacent letters in a grid.
        The letters will disappear and new ones will fall down. You have to
        clear as many rows and columns as possible before the time runs out.
        Some of the best websites for playing word wipe online are Arkadium,
        Wordgames.com, and Mindgames.com. <br></br>Word games online are not
        only fun, but also beneficial for your brain. They can help you improve
        your spelling, vocabulary, memory, concentration, and problem-solving
        skills. They can also reduce stress, boost your mood, and prevent
        cognitive decline. So what are you waiting for? Start playing some word
        games online today and see how much you can learn and enjoy!
      </p>

      <h1>Word Quiz</h1>
      <p className="text-left text-sm text-gray-500 dark:text-gray-300">
        If you are a fan of word games and love to challenge your vocabulary
        skills, then you'll want to check out "Word Quiz" – the ultimate test
        for any language enthusiast. <br></br>
        "Word Quiz" is a mobile game app that will put your language skills to
        the test. The game is designed to be both educational and entertaining,
        making it perfect for players of all ages.<br></br>
        The game consists of several levels, each with a set of questions that
        will challenge your knowledge of words and their meanings. Each level
        has a different theme, ranging from general vocabulary to more specific
        topics such as science, literature, or pop culture. <br></br>To play the
        game, you are given a word and must select the correct meaning from a
        list of possible answers. The game also offers helpful hints and
        lifelines, such as removing incorrect answers or revealing the first
        letter of the word.<br></br>
        As you progress through the levels, the questions become more
        challenging, requiring you to dig deeper into your vocabulary knowledge
        to find the correct answer. But don't worry, the game is designed to be
        fun and engaging, so you'll never feel discouraged or bored.<br></br>
        "Word Quiz" also offers a multiplayer mode, where you can compete
        against other players from around the world to see who has the best
        vocabulary skills. This adds an extra layer of excitement and
        competitiveness to the game.<br></br>
        The app is free to download and play, and offers in-app purchases for
        additional features such as ad removal and extra hints. The game is
        available for both iOS and Android devices, making it accessible to a
        wide audience. <br></br>In conclusion, "Word Quiz" is a fun and
        addictive game that will challenge your vocabulary skills and keep you
        entertained for hours. With its variety of themes and challenging
        questions, it is the perfect game for anyone looking to improve their
        language skills or simply have some fun with words. So, download "Word
        Quiz" today and put your vocabulary to the test!
      </p>
      <p className="text-left text-sm text-gray-500 dark:text-gray-300">
        <br></br>
        <br></br>
        Contact Us: guesswordfun@gmail.com
      </p>
    </BaseModal>
  )
}
