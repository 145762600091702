import React from 'react'

import { Cell } from '../components/grid/Cell'

const Howto = () => {
  return (
    <div className="dark:text-white">
      <p className="text-center text-sm text-gray-500 dark:text-gray-300">
        Guess a word in 6 tries. There will be hints like number of characters,
        first letter, last letter, first two letters, and last two letters of
        the word along the way.
        <br></br>
        <br></br>
        The keyboard also gives hints:
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="M"
          status="present"
        />
      </div>
      <p className="text-center text-sm text-gray-500 dark:text-gray-300">
        The letter M is in the word.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell isRevealing={true} isCompleted={true} value="O" status="absent" />
      </div>
      <p className="text-center text-sm text-gray-500 dark:text-gray-300">
        The letter O is not in the word.
        <br></br>
        <br></br>
        Contact Us: guesswordfun@gmail.com
      </p>
    </div>
  )
}

export default Howto
