import React from 'react'

// import Toggle from './ThemeToggle'

export const Navbar = () => {
  return (
    <nav className="mx-2 rounded border-gray-200 bg-white px-2 py-2.5 dark:bg-gray-800">
      <div className="container mx-auto flex items-center justify-between pt-3">
        <div className="mx-auto flex items-center">
          <span className="whitespace-nowrap text-xl font-medium dark:text-white">
            Guess Word for Fun
          </span>
        </div>
      </div>
    </nav>
  )
}

//export default Navbar
