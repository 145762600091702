import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-left text-sm text-gray-500 dark:text-gray-300">
        Guess a word in 6 tries. There will be hints like number of characters,
        first letter, last letter, first two letters, and last two letters of
        the word along the way.
        <br></br>
        <br></br>
        The keyboard also gives hints:
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="M"
          status="present"
        />
      </div>
      <p className="text-left text-sm text-gray-500 dark:text-gray-300">
        The letter M is in the word.
      </p>

      <div className="mb-1 mt-4 flex justify-center">
        <Cell isRevealing={true} isCompleted={true} value="O" status="absent" />
      </div>
      <p className="text-left text-sm text-gray-500 dark:text-gray-300">
        The letter O is not in the word.
        <br></br>
        <br></br>
        Contact Us: guesswordfun@gmail.com
      </p>
    </BaseModal>
  )
}
