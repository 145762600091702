import React, { useState } from 'react'
//import { BsArrowLeftCircle } from 'react-icons/bs'
import { Link, useLocation } from 'react-router-dom'

//import { AiFillPieChart } from 'react-icons/ai'
//import { SiFuturelearn } from 'react-icons/si'
//import { SiOpenaccess } from 'react-icons/si'
//import { CgProfile } from 'react-icons/cg'
//import Logo from '../assets/images/logo.svg'
import HamburgerButton from './HamburgerMenuButton/HamburgerButton'

const Sidebar = () => {
  const [open, setOpen] = useState(true)
  const [mobileMenu, setMobileMenu] = useState(false)
  const location = useLocation()

  const Menus = [
    { title: 'Daily Game', path: '/' },
    { title: 'How to play', path: '/Howto' },
    { title: 'Past Games', path: '/Pastgames' },
    { title: 'Blogs', path: '/Blog' },
    // { title: 'Stats', path: '/Statsmodal' },
    //   { title: 'Signin', path: '/login' },
  ]

  return (
    <>
      <div
        className={`${
          open ? 'w-60' : 'w-fit'
        } relative hidden h-screen border-r border-gray-200 bg-gray-100 p-5 duration-300 dark:border-gray-600 dark:bg-slate-800 sm:block`}
      >
        <Link to="/">
          <div className={`flex ${open && 'gap-x-4'} items-center`}>
            {open && (
              <span className="whitespace-nowrap text-xl font-medium dark:text-white"></span>
            )}
          </div>
        </Link>

        <ul className="pt-6">
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index}>
              <li
                className={`flex cursor-pointer items-center gap-x-6 rounded-lg p-3 text-base font-normal hover:bg-gray-200 dark:text-white dark:hover:bg-gray-700
                               ${menu.gap ? 'mt-9' : 'mt-2'} ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <span
                  className={`${
                    !open && 'hidden'
                  } origin-left duration-300 hover:block`}
                >
                  {menu.title}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      </div>
      {/* Mobile Menu */}
      <div className="pt-3">
        <HamburgerButton
          setMobileMenu={setMobileMenu}
          mobileMenu={mobileMenu}
        />
      </div>
      <div className="sm:hidden">
        <div
          className={`${
            mobileMenu ? 'flex' : 'hidden'
          } md absolute left-6 right-6 z-50 mt-16 flex-col items-center space-y-6 self-end rounded-xl bg-gray-50 py-8  font-bold drop-shadow dark:bg-slate-800 dark:text-white sm:w-auto`}
        >
          {Menus.map((menu, index) => (
            <Link
              to={menu.path}
              key={index}
              onClick={() => setMobileMenu(false)}
            >
              <span
                className={` ${
                  location.pathname === menu.path &&
                  'bg-gray-200 dark:bg-gray-700'
                } rounded-xl p-2 hover:bg-gray-200 dark:hover:bg-gray-700`}
              >
                {menu.title}
              </span>
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}

export default Sidebar
